<template>
  <base-mobile-page>
    <template v-slot:main>
      <el-main style="padding:20px 0px;margin:0 auto;width:100%;max-width:1300px;">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:0px 10px 10px 20px;">
          <el-breadcrumb-item to="/">Home</el-breadcrumb-item>
          <el-breadcrumb-item>{{currentCategory.name}}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="product-list-row" v-loading="loading">
            <div class="product-item-col" :span="12" v-for="item in products" :key="item.ID">
                <product-item style="margin:10px;" :item="item"></product-item>
            </div>
        </div>
        <div style="margin-top:40px;">
            <el-pagination background layout="prev, pager, next" :total="productCount" :page-size="pageSize" :current-page="pageIndex" @current-change="handlePageChange"></el-pagination>
        </div>
      </el-main>
    </template>
  </base-mobile-page>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import BaseMobilePage from '../../components/base/BaseMobilePage.vue'
import ProductItem from '../../components/widgets/ProductItem.vue'
import ModelProductList from '../viewmodel/ModelProductList.vue'

export default {
  extends: ModelProductList,
  name: 'productList',
  components:{
    BaseMobilePage,
    ProductItem
  },
  computed: {
    ...mapState('client', ['browserFrom'])
  },
  mounted() {
    if(this.browserFrom == 'app') {
      this.getActiveCategoryList()
    }
  },
  methods: {
    ...mapActions('category', [
      'getActiveCategoryList'
    ]),
  }
}
</script>

<style>
.product-list-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding:0px 10px;
}
.product-item-col{
    flex: 1 0 49%;
}
</style>