<template>
  <el-card id="productItem" shadow="never" :body-style="{ padding: '5px', border: '1px solid #fff' }" @click.native="showProduct">
    <el-image fit="cover" class="productImage" :src="displayImage"></el-image>
    <h3 style="font-size:18px;font-weight:400;text-align:left;">{{item.name}}</h3>
    <div style="display:-webkit-box;margin-top:-15px;margin-bottom:5px;">
        <div style="font-size:12px;">{{item.merchant.name}}</div>
      <!--<div style="text-align:left;"><el-rate v-model="item.review_score" disabled disabled-void-color="#C6D1DE"></el-rate></div>
      <div style="font-size:15px;">{{item.review_count}} reviews</div>-->
    </div>
    <div v-if="sku.price > 0" style="text-align:left;font-size:15px;"><span style="color:#F56C6C;text-decoration:line-through;">${{(sku.price/100).toFixed(2)}}</span> from ${{(sku.member_price/100).toFixed(2)}}</div>
    <div v-else style="text-align:left;font-size:15px;">${{(sku.member_price/100).toFixed(2)}}</div>
  </el-card>
</template>

<script>
export default {
    name: 'productItem',
    props: ['item'],
    data(){
        return {
            mouseIn: false,
            value: 0
        }
    },
    computed: {
        displayImage(){
            if(this.mouseIn && this.item.images.length > 1){
                return this.item.images[1].image
            } else {
                return this.item.image
            }
        },
        sku(){
            if(this.item.skus.length == 0){
                return {
                    ID: 0,
                    price: 0,
                    member_price: 0
                }
            }
            var sku = this.item.skus[0]
            if(this.item.skus.length > 1){
                this.item.skus.forEach(el => {
                    if(el.member_price < sku.member_price){
                        sku = el
                    }
                })
            }
            return sku
        }
    },
    mounted(){
        console.log(this.item)
    },
    methods: {
        showProduct(){
            console.log('click')
            window.location.href = '/product/' + this.item.ID
        }
    }
}
</script>

<style>
#productItem{
    background-color: #fff;
    cursor: pointer;
    border: 1px solid #fff !important;
}
.productImage{
    width: 100%;
    max-height: 284px;
    cursor: pointer;
}
</style>