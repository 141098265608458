<template>
  <div></div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
    name: 'productList',
    computed: {
        ...mapState('category', [
            'categories'
        ]),
        ...mapState('product', [
            'products',
            'productCount',
            'pageSize',
            'pageIndex',
            'loading'
        ]),
        currentCategory(){
            var category = this.$route.params.categoryName
            if(category === 'all'){
                return {
                    ID: 0,
                    name: 'All Products'
                }
            }
            for(var i = 0; i < this.categories.length; i++){
                if(this.categories[i].name === category){
                    return this.categories[i]
                }
            }
            return ''
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        ...mapActions('product', [
            'setActiveCategory',
            'setActivePageIndex'
        ]),
        init(){
            this.setActiveCategory({
                index: this.currentCategory.ID
            })
        },
        handlePageChange(val){
            this.setActivePageIndex({
                pageIndex: val
            })
            window.scroll(0, 0)
        },
    }
}
</script>

<style>
.product-list-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding:0px 10px;
}
.product-item-col{
    flex: 1 0 49%;
}
</style>